<script setup>
import TopBar from "~/components/organisms/TopBar.vue";
import Header from "~/components/organisms/Header.vue";
import {defineAsyncComponent} from 'vue'
import FooterBlock from "~/components/organisms/FooterBlock.vue";
import {useStartupStore} from "~/store/sturtupStore";
import {useCartStore} from "~/store/cartStore.js";
import {useQuotationStore} from "~/store/quotationStore.js";
import {useRoute} from "#vue-router";

const startupStore = useStartupStore();
const cartStore = useCartStore();
const quotationStore = useQuotationStore();

const SideMenu = defineAsyncComponent({
  loader: () => import('~/components/organisms/SideMenu.vue'),
  onError(error, retry, fail, attempts) {
    console.error(`Error loading Side Menu ${attempts} спроб:`, error)
    fail()
  }
})

const Quotation = defineAsyncComponent({
  loader: () => import('~/components/organisms/Quotation.vue'),
  onError(error, retry, fail, attempts) {
    console.error(`Error loading Quotation ${attempts} спроб:`, error)
    fail()
  }
})

const {t} = useI18n();

useHead({
  meta: [
    useRuntimeConfig().public.APP_ENV === 'production' && !useRoute().query.search && !useRoute().query.fs ? {
      name: 'robots',
      content: 'index, follow'
    } : {
      name: 'robots',
      content: 'noindex, nofollow'
    },
    {
      hid: 'og:locale',
      property: 'og:locale',
      content: useRuntimeConfig().public.locale
    },
    {
      hid: 'og:site_name',
      property: 'og:site_name',
      content: 'Hill Ceramic'
    },
  ],
  script: [
    {
      type: 'application/ld+json',
      children: {
        "@context": "https://schema.org",
        "@type": "Organization",
        "url": t('meta.domain'),
        "logo": t('meta.logo'),
      }
    },
  ]
})

onMounted(() => {
  cartStore.getContent()
})
</script>

<template>
  <div class="main-layout">
    <div>
      <header>
        <TopBar/>
        <Header/>
      </header>
      <SideMenu v-if="startupStore?.isMenuInitialised"/>
      <Quotation v-if="quotationStore?.isInit"/>
      <main>
        <slot/>
      </main>
    </div>
    <footer>
      <FooterBlock/>
    </footer>
  </div>
</template>
